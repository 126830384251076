import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { clamp } from 'lodash-es';
import { OvertimePolicyModel } from './overtime-policy.model';

export interface OvertimePolicyState extends EntityState<OvertimePolicyModel> {
  isLoading?: boolean;
  error?: any;
}

export const overtimePolicyEntityAdapter: EntityAdapter<OvertimePolicyModel> = createEntityAdapter<OvertimePolicyModel>(
  {
    sortComparer: (a, b) => {
      const orderA = parseInt(a.order ?? '1', 10);
      const orderB = parseInt(b.order ?? '1', 10);

      if (orderA === orderB) {
        return a.name.localeCompare(b.name);
      }
      return clamp(orderA - orderB, -1, 1);
    },
  }
);

export const initialState: OvertimePolicyState = overtimePolicyEntityAdapter.getInitialState();
